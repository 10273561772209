<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
    <div>

        <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'intentoDashboard' }" v-if="isIntentoUser">
                <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
                Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'customerDashboard' }" v-if="isIntentoSales">
                Customer Management
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'customerSites' }">
                {{ customer.company }}
            </b-breadcrumb-item>
        </b-breadcrumb>

        <b-container fluid>

            <b-card>
                <b-card-title>
                    {{ customer.company }}
                </b-card-title>
                <b-card-body>

                    <b-row>
                        <b-col cols="4">

                            <strong>Address</strong> <br />

                            <div v-if="customer.address || customer.city || customer.zipCode || customer.country">

                                <span v-if="customer.address">
                                {{ customer.address }}  <br />
                                </span>

                                <span v-if="customer.city">
                                {{ customer.city }} <br />
                                </span>

                                <span v-if="customer.zipCode">
                                {{ customer.zipCode }} <br />
                                </span>

                                <span v-if="customer.country">
                                {{ customer.country }} <br />
                                </span>

                            </div>
                            <div v-else>
                                Not provided
                            </div>

                            <br />

                            <span v-if="customer.vatNumber">
                                <strong>VAT</strong> <br /> {{ customer.vatNumber }}
                            </span>

                        </b-col>
                        <b-col cols="4">
                            <strong>Contact</strong> <br />

                            <div v-if="customer.contactFirstName || customer.contactLastName || customer.contactEmail || customer.contactPhone">

                                <span v-if="customer.contactFirstName">
                                    {{ customer.contactFirstName }}
                                </span>

                                <span v-if="customer.contactLastName">
                                    {{ customer.contactLastName }} <br />
                                </span>

                                <span v-if="customer.contactEmail">
                                    Email: {{ customer.contactEmail }} <br />
                                </span>

                                <span v-if="customer.contactPhone">
                                    Phone: {{ customer.contactPhone }} <br />
                                </span>

                            </div>
                            <div v-else>
                                Not provided
                            </div>

                        </b-col>
                        <b-col cols="4">
                            <b-button block v-if="isIntentoSales"
                                      variant="outline-primary"
                                      v-b-modal.modal-edit-customer
                                      @click.prevent="populateEditModel()">
                                <b-icon-pencil /> Edit Customer Data
                            </b-button>
                        </b-col>
                    </b-row>

                    <b-card class="mt-5">

                         <b-nav tabs>
                            <b-nav-item :to="{ name: 'customerSites'}" exact exact-active-class="active">Sites</b-nav-item>
                            <b-nav-item v-if="isIntentoSales" :to="{ name: 'customerAccountManagers'}" exact exact-active-class="active">Account Managers</b-nav-item>
                        </b-nav>
                        <router-view />

                    </b-card>

                </b-card-body>

            </b-card>


        </b-container>

        <b-modal id="modal-edit-customer"
                 @ok="saveCustomer"
                 ok-title="Save Customer"
                 :title="'Edit Customer'"
                 size="lg">
            <form @submit.prevent="saveCustomer" >
                <b-row>
                    <b-col>
                        <b-form-group label="Company">
                            <b-form-input type="text" v-model="customerEditModel.company"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Address">
                            <b-form-input type="text" v-model="customerEditModel.address"></b-form-input>
                        </b-form-group>
                        <b-form-group label="City">
                            <b-form-input type="text" v-model="customerEditModel.city"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Zip Code">
                            <b-form-input type="text" v-model="customerEditModel.zipCode"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Country">
                            <b-form-input type="text" v-model="customerEditModel.country"></b-form-input>
                        </b-form-group>
                        <b-form-group label="VAT Number">
                            <b-form-input type="text" v-model="customerEditModel.vatNumber"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Contact First name">
                            <b-form-input type="text" v-model="customerEditModel.contactFirstName"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Contact Last name">
                            <b-form-input type="text" v-model="customerEditModel.contactLastName"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Contact Phone">
                            <b-form-input type="text" v-model="customerEditModel.contactPhone"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Contact email">
                            <b-form-input type="text" v-model="customerEditModel.contactEmail"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
            <div class="form-group">
                <div v-if="errorMessage" class="alert alert-danger" role="alert">
                    {{ errorMessage }}
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import ApiService from '@/services/api.service';

    export default {
        props: {
            customerId: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                customer: {},
                customerEditModel : {},
                errorMessage: ''
            };
        },
        async created() {
            this.refreshCustomer();
        },
        computed: {
            ...mapGetters('auth', ['isIntentoSales', 'isIntentoUser'])
        },
        methods: {
            async refreshCustomer() {
                this.loading = true;
                this.customer = await ApiService.getCustomerAccount(this.customerId);
                this.loading = false;
            },
            async saveCustomer(event) {
                event.preventDefault();
                await ApiService.updateCustomerAccount(this.customer.id, this.customerEditModel).then(
                            () => { 
                                // Hide the modal manually
                                this.$nextTick(() => {
                                    this.$bvModal.hide("modal-edit-customer");
                                });
                            },
                            (error) => {
                                    this.errorMessage =
                                    (error.response && error.response.data)
                                    || error.message
                                    || error.toString();
                                });
                this.customer = Object.assign({}, this.customerEditModel);
            },
            populateEditModel() {
                this.customerEditModel = Object.assign({}, this.customer);
                this.errorMessage = '';
            }
        }
    };
</script>

<style scoped>

    .active {
        background: #20C997 !important;
        color: white !important;
    }
</style>